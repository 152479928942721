:root {
  --PrimaryColor:hsl(26,93%,50%);
  --HoverColor:hsl(26,90%,42%);
  --whiteColor:hsl(0,0%,100%);
  --blackColor:hsl(0,0%,18%);
  --textColor:hsl(240,1%,48%);
  --whiteColorDeam:hsl(0,0%,93%);
  --greyBg:hsl(0,0%,96%);
  --greytext:rgb(190,190,190);
  --inputColor:hsl(330,12%,97%);
}

.footer {
  background: var(--greyBg);
  padding: 0.5rem 0;
}
.footer .secContainer {
  gap: 1.5rem;
  text-align: center;
}
.footer .secContainer .footerLogo {
  padding-bottom: 0.5rem;
}
.footer .secContainer .footerLogo img {
  height: 40%;
  width: 40%;
  -o-object-fit: cover;
     object-fit: cover;
  justify-content: center;
}
.footer .socials {
  gap: 0.6rem;
  margin: auto;
  margin-top: -10%;
  justify-content: center;
}
.footer .socials .icon {
  font-size: 30px;
  background: var(--PrimaryColor);
  border-radius: 50%;
  padding: 8px;
  color: var(--whiteColor);
}
.footer .socials .icon:hover {
  background: var(--blackColor);
}
.footer .footerLinks {
  display: grid;
  row-gap: 0.2rem;
}
.footer .footerLinks .linkTitle {
  padding-bottom: 1rem;
  color: var(--blackColor);
  font-weight: 700;
}
.footer .footerLinks li:hover {
  transform: translateX(10px);
}
.footer .footerLinks li:hover a {
  color: var(--PrimaryColor);
}
.footer .footerLinks a, .footer .footerLinks .Phone, .footer .footerLinks .email, .footer .footerLinks .adress {
  color: var(--textColor);
  font-size: 15px;
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  .secContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (min-width: 900px) {
  .secContainer {
    padding: 2rem;
  }
}
@media screen and (min-width: 420px) {
  .secContainer {
    padding: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .secContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=footer.css.map */