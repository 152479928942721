:root {
  --PrimaryColor:hsl(26,93%,50%);
  --HoverColor:hsl(26,90%,42%);
  --whiteColor:hsl(0,0%,100%);
  --blackColor:hsl(0,0%,18%);
  --textColor:hsl(240,1%,48%);
  --whiteColorDeam:hsl(0,0%,93%);
  --greyBg:hsl(0,0%,96%);
  --greytext:rgb(190,190,190);
  --inputColor:hsl(330,12%,97%);
}

.project {
  padding-top: 1rem;
}
.project .secContainer .mainContent {
  gap: 0.5rem;
}
.project .secContainer .mainContent .singleProject {
  border-radius: 1rem;
  overflow: hidden;
  padding: 0.5rem;
  box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.189);
}
.project .secContainer .mainContent .singleProject small {
  font-weight: 500;
  color: var(--textColor);
}
.project .secContainer .mainContent .singleProject .projectImage {
  position: relative;
  height: 200px;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
}
.project .secContainer .mainContent .singleProject .projectImage .projectdonationcount {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
}
.project .secContainer .mainContent .singleProject .projectBody {
  padding: 0.5rem;
  height: 13rem;
}
.project .secContainer .mainContent .singleProject .projectBody .Title {
  justify-content: space-between;
}
.project .secContainer .mainContent .singleProject .projectBody .Title h4 {
  color: var(--blackColor);
  font-weight: 700;
  font-size: 1.1rem;
}
.project .secContainer .mainContent .singleProject .projectBody .Title h4 p {
  line-height: 20px;
}
.project .secContainer .mainContent .singleProject .projectBody .Title .status {
  padding: 5px 10px;
  color: var(--HoverColor);
  background: var(--greyBg);
  font-size: 10px;
  font-weight: 600;
  border-radius: 3rem;
}
.project .secContainer .mainContent .singleProject .projectBody .Details {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 1rem 0;
}
.project .secContainer .mainContent .singleProject .projectBody .Details p {
  height: 2rem;
  text-align: left;
}
.project .secContainer .mainContent .singleProject .btn {
  margin-top: 1rem;
  width: 40%;
  text-align: center;
  justify-content: center;
  padding: 0.6rem;
  color: var(--whiteColor);
  font-weight: 600;
  gap: 0.5rem;
}
.project .secContainer .mainContent .singleProject:hover {
  transform: translateY(-10px);
}

@media screen and (min-width: 480px) {
  .project {
    padding-top: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .project .secContainer .secIntro {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .project .secContainer .secIntro .secTitle {
    text-align: center;
    max-width: 70%;
  }
  .project .secContainer .secIntro .secTitle h4 {
    color: var(--blackColor);
    font-weight: 700;
  }
  .project .secContainer .secIntro .secTitle p {
    color: var(--blackColor);
    font-weight: 500;
    font-size: 2.2rem;
    text-align: center;
  }
  .project .secContainer .secIntro .iconsDiv {
    justify-content: flex-end;
  }
  .project .secContainer .mainContent {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem !important;
  }
}
@media screen and (min-width: 480px) {
  .project .secContainer .secIntro .secTitle {
    text-align: center;
  }
  .project .secContainer .secIntro .secTitle p {
    line-height: 35px;
    margin-left: 50px;
  }
}
@media screen and (min-width: 412px) {
  .project .secContainer .secIntro .secTitle {
    text-align: center;
  }
  .project .secContainer .secIntro .secTitle p {
    line-height: 35px;
    margin-left: 50px;
  }
}
@media screen and (min-width: 430px) {
  .project .secContainer .secIntro .secTitle {
    text-align: center;
  }
  .project .secContainer .secIntro .secTitle p {
    line-height: 35px;
    margin-left: 50px;
  }
}/*# sourceMappingURL=project.css.map */