@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: 3s ease;
}


:root {

  --PrimaryColor: hsl(26, 93%, 50%);
  --HoverColor: hsl(26, 90%, 42%);
  --whiteColor: hsl(0, 0%, 100%);
  --blackColor: hsl(0, 0%, 18%);
  --textColor: hsl(240, 1%, 48%);
  --whiteColorDeam: hsl(0, 0%, 93%);
  --greyBg: hsl(0, 0%, 96%);
  --greytext: rgb(190, 190, 190);
  --inputColor: hsl(330, 12%, 97%);
  /*box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);*/

}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 2rem;
  cursor: pointer;
  color: black;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.btn {
  padding: .5rem 1.5rem;
  background: var(--PrimaryColor);
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
}

.btn a {
  color: var(--whiteColor);
  font-weight: 500;

}

.btn:hover a {
  color: var(--whiteColor);
}

.secIntro {
  max-width: 70%;
  text-align: center;
  margin: 0auto 2rem;
}

.secIntro .secTitle {
  color: var(--blackColor);
  line-height: 1.5rem;
  padding-bottom: 5rem;
}

.secIntro p {
  font-size: 33px;
  color: var(--textColor);
  font-weight: 500;
}

img,
video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
}

@media screen and (min-width:556px){
  .mainContent{

    grid-template-columns: repeat(2,1fr);
  }

}

@media screen and (min-width:884px){
  .mainContent{

    grid-template-columns: repeat(3,1fr);
  }

}
@media screen and (min-width:1240px){
  .container{
     width: 75% !important;
     margin: auto;
  }

  .section{
    padding: 3rem 0 3rem;

  }
  .secIntro{
    margin:0 auto 3rem;
  }
  .secIntro .secTitle{
    line-height: 2rem;
    padding-bottom: 1rem;
    font-size: 2.2rem;

  }

  .secIntro p{
    font-size: 15px;
  }

}

@media screen and(min-width:2560px) {
  body{
    zoom:1.7
  }
}
/* Sayfa dizayn */
::selection{
  background: var(--PrimaryColor);
  color:var(--whiteColor);
}

::-webkit-scrollbar{
  width: 10px;
  background: var(--greyBg);
}

::-webkit-scrollbar-thumb{
  background: var(--PrimaryColor);
  border-radius: 10px;
  cursor: pointer;
}