:root{

    --PrimaryColor:hsl(26,93%,50%);
    --HoverColor:hsl(26,90%,42%);
    --whiteColor:hsl(0,0%,100%);
    --blackColor:hsl(0,0%,18%);
    --textColor:hsl(240,1%,48%);
    --whiteColorDeam:hsl(0,0%,93%);
    --greyBg:hsl(0,0%,96%);
    --greytext:rgb(190,190,190);
    --inputColor:hsl(330,12%,97%);

}

.about{

    .secContainer{
        .title{
            color:var(--blackColor);
            line-height: 1.5rem;
            padding-bottom: .5rem;
            text-align: center;
            font-size: 2rem;
            
            

        }

        .mainContent{
            gap: 3rem;
            .singleItem{
                text-align: center;
                height: 13rem;
              

                img{
                    background:rgba( 247,144,54,0.265);
                    width: 100px;
                    border-radius: 50%;
                    padding: 1rem;
                }
                h3{
                    text-align: center;
                    justify-content: center;
                    font-weight: 700;
                    color: var(--blackColor);
                    padding: .5rem;
                    height: 2rem;
                }
                p{
                    text-align: center;
                    justify-content: center;
                    font-size: 13px;
                    font-weight: 500;
                    padding-bottom: 1rem;
                    padding-top: 1.8rem;
                    width: 320px;
                   padding-left: 10%;
                }
            }
        }

        .videoCard{
            margin-top: 2rem;
            background: linear-gradient(to top,rgba(0,0,0,0.73),rgba(0,0,0,0.609)),url(../../Assets/BirFarkYarat.png);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            .cardContent{
                gap:2rem;
                padding: 2rem 1rem;

                
                .cardText{
                    h2{
                        color:var(--whiteColor);
                        font-weight: 600;
                        padding-bottom:1rem ;
                    }
                    p{
                        color: var(--whiteColor);
                        opacity: .7;
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 1px;
                    }

                  
                }
           
            }

            .cardVideo{
                border:2px solid var(--whiteColor);
                height: 300px;
                width: 100%;
                border-radius: 30px;
                overflow: hidden;

                video{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}


@media screen and (min-width:720px) {

    .about{
        .secContainer{
            .videoCard{
                .cardContent{
                    gap:3rem;
                    padding: 4rem 3rem;
                    .cardText{
                        h2{
                            font-size: 2rem;
                        }

                        p{
                            opacity: .7;
                            font-size: 12px;
                        }
                    }
                }

                .cardContent{
                    grid-template-columns: repeat(2,1fr)!important;
                }

            }
        }
    }

    
}



@media screen and (min-width:1024px) {

    .about{
        .secContainer{
            .mainContent{
                padding: 2.5rem 0;
            }
        }
    }

    
}
@media screen and (min-width:420px) {

    .about{
        .secContainer{
            .mainContent{
                padding: 3rem 0;
            }
        }
    }

    
}

@media screen and (min-width:420px) {
    .about{
        .secContainer{
            .mainContent{
               

                .singleItem{

                    p{
                        margin-left: 10%;
                        margin-bottom: 20px;
                        text-align: center;
                        justify-content: center;
                        
                    }
                }
            }
        }
    }

    
}

@media screen and (min-width:720px) {
    .about{
        .secContainer{
            padding: 2rem 0;
            .mainContent{
                padding: 3rem 0;

                .singleItem{

                    p{
                        margin-left: 2.5%;
                       padding-top: 35px;
                        text-align: center;
                        justify-content: center;
                        
                    }
                }
            }
        }
    }

    
}