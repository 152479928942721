:root{

    --PrimaryColor:hsl(26,93%,50%);
    --HoverColor:hsl(26,90%,42%);
    --whiteColor:hsl(0,0%,100%);
    --blackColor:hsl(0,0%,18%);
    --textColor:hsl(240,1%,48%);
    --whiteColorDeam:hsl(0,0%,93%);
    --greyBg:hsl(0,0%,96%);
    --greytext:rgb(190,190,190);
    --inputColor:hsl(330,12%,97%);

}

.home{

    height:80vh;
    background: linear-gradient(rgba(33,33,33,0.522),
    rgba(33,33,33,0.522)),url('../../Assets/s.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;

    .secContainer{
        .homeText{
            text-align: center;

            .title{
                color:var(--whiteColor);
                font-size: 2.5rem;
                font-weight: 700;
                
                text-align: center;
            }

            .subTitle{
                color:var(--whiteColor);
                opacity: .9;
                font-size: 25px;
                font-weight: 400;
                padding: 1rem -0;
                max-width: 70%;
                text-align: center;
                margin: auto;
            }

            .btn{
                padding: .6rem 1.5rem;
            }
        }

        .homeCard{
            width: 68%;
            border-radius: 3rem;
            padding:3rem;
            background:var(--whiteColor);
            position:absolute;
            gap:1rem;
            justify-content: center;
            bottom: -30%;
            transform: translate(-50%);
            left:50%;
            box-shadow: 0 2px 8px 4px rgba(178,178,178,0.45);

            .locationDiv,.distDiv,.priceDiv{
                width: 100%;

                label{
                    display: block;
                    color:var(--textColor);
                    font-weight: 500;
                    font-size:15px;
                    padding-bottom: .4rem;
                }

                input{
                    width: 100%;
                    background: var(--inputColor);
                    border-radius: 10px;
                    border: none;
                    padding: 10px;
                    font-size: 13px;
                    font-weight: 500;

                    &::placeholder{
                        font-size: 13px;
                        opacity: .5;
                        font-weight: 400;
                    }
                    &:focus{
                        outline: none;
                    }
                }
            }

            .btn{
                margin-top: 20px;
                width: 100%;
                padding: .6rem 1.5rem;
                color: var(--whiteColor);
                font-weight: 600;
            }
        }
    }
}
    
@media screen and (min-width:480px) {

    .home{
        .secContainer{
            .homeCard{
                width: 60%;
                grid-template-columns: repeat(1,1fr);
                text-align: center;
                .btn{
                    transform: translateY(10px);
                }
            }
        }
    }

    
}

@media screen and (min-width:1024px) {

    .home{
       
        .secContainer{
             padding-bottom: 10%;
             
             .homeText{
                .title{
                 font-size: 2.2rem;
                 padding: 10px;
                 padding-top: 10px;
                 line-height: 35px;
                }
                .subTitle{
                 font-size:20px ;
                }
             }
        }
    }

    
}


@media screen and (min-width:480px) {

    .home{
        .secContainer{
            .homeText{
               .title{
                font-size: 2.2rem;
                padding: 10px;
                padding-top: 2px;
                line-height: 35px;
               }
               .subTitle{
                font-size:20px ;
               }
            }
        }
    }

    
}

@media screen and (min-width:720px) {

    .home{
        .secContainer{
            .homeCard{
                bottom: -15%;

            }
        }
    }

    
}
@media screen and (min-width:720px) {

    .home{
        .secContainer{
            .homeText{
               padding: 5rem 10rem;

               .title{
                font-size: 3.5rem;
                margin: auto;
                line-height: 4rem;
               }

               .subTitle{
                width: 70%;
               }

            }
        }
    }

    
}
.modal {
    display: none; /* Modal başlangıçta görünmez */
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Arka planın yarı saydam olması için */
}

.modal-content {
    background-color: #fff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    text-align: center;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}
@media screen and (min-width:420px) {

    .home{
        .secContainer{
            .homeText{
                .title{
               h1{
                font-size: 2.2rem;
                padding: 20px;
                padding-top: 2px;
                line-height: 35px;
               
               }}
            }
        }
    }

    
}

