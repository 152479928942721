:root{

    --PrimaryColor:hsl(26,93%,50%);
    --HoverColor:hsl(26,90%,42%);
    --whiteColor:hsl(0,0%,100%);
    --blackColor:hsl(0,0%,18%);
    --textColor:hsl(240,1%,48%);
    --whiteColorDeam:hsl(0,0%,93%);
    --greyBg:hsl(0,0%,96%);
    --greytext:rgb(190,190,190);
    --inputColor:hsl(330,12%,97%);

}

.footer
{
    background: var(--greyBg);
    padding: .5rem 0;
    
    
     .secContainer{
                       gap:1.5rem;
                       text-align: center;


        .footerLogo{
                     
                           
                           padding-bottom: .5rem;

                           img{
                            height: 40%;
                            width:40%;
                            object-fit: cover;
                           justify-content: center;
                               }

                              
                    
                         
                         }
                    
                    }
                          .socials{
                            gap: .6rem;
                            margin: auto;
                            margin-top: -10%;
                            justify-content: center;
                                   .icon{
                                    font-size: 30px;
                                    background:var(--PrimaryColor) ;
                                    border-radius: 50%;
                                    padding: 8px;
                                    color: var(--whiteColor);
                                    &:hover{
                                        background: var(--blackColor);
                                    }
                                   }

                               }

                               .footerLinks{
                                display: grid;
                                row-gap: .2rem;
                                .linkTitle{
                                    
                                    padding-bottom: 1rem;
                                    color: var(--blackColor);
                                    font-weight: 700;
                                           }

                                           li{
                                            &:hover{
                                                transform: translateX(10px);
                                            a{
                                                color: var(--PrimaryColor);
                                            }
                                            }
                                           }

                                           a,.Phone,.email,.adress{
                                              color:var(--textColor);
                                              font-size: 15px;
                                              font-weight: 500;
                                            
                                           }
                               }
}

@media screen and (min-width:640px) {
    .secContainer{
        grid-template-columns: repeat(3,1fr);
    }
    
}

@media screen and (min-width:900px) {
    .secContainer{
        padding:2rem;
        
    }
    
}


@media screen and (min-width:420px) {
    .secContainer{
        padding:1rem;
        
    }
    
}

@media screen and (min-width:1024px) {
    .secContainer{
        
        grid-template-columns: repeat(3,1fr);
    }
    
}