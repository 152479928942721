:root{

    --PrimaryColor:hsl(26,93%,50%);
    --HoverColor:hsl(26,90%,42%);
    --whiteColor:hsl(0,0%,100%);
    --blackColor:hsl(0,0%,18%);
    --textColor:hsl(240,1%,48%);
    --whiteColorDeam:hsl(0,0%,93%);
    --greyBg:hsl(0,0%,96%);
    --greytext:rgb(190,190,190);
    --inputColor:hsl(330,12%,97%);

}
.project{
    padding-top: 1rem;
   .secContainer{
 
       .mainContent{

        

       gap:.5rem;

       .singleProject{

        border-radius: 1rem;
        overflow: hidden;
        padding: .5rem;
        box-shadow: 0 2px 8px 2px rgba(178,178,178,0.189);

       small{
        font-weight: 500;
        color: var(--textColor);
       }

        .projectImage{
            position: relative;
            height: 200px;
            width: 100%;
            border-radius: 1rem;
            overflow: hidden;

            img{
                // height: 100%;
            }
            
            .projectdonationcount{
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 10px;
            }

        }
       .projectBody{
        padding: .5rem;
        
        height:13rem;
             .Title{
                justify-content: space-between;
              

                h4{
                    
                color:var(--blackColor);
                font-weight: 700;
                font-size: 1.1rem;
                p{
                    line-height: 20px;
                }
                
            }
               .status{

                padding: 5px 10px;
                color: var(--HoverColor);
                background: var(--greyBg);
                font-size: 10px;
                font-weight:600 ;
                border-radius: 3rem;
                
               }

             }

             .Details{
               
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding:1rem 0;

                p{
                    height:2rem;
                    text-align:left;
                }

             }
       }
          .btn{
            margin-top: 1rem;
            width: 40%;
            text-align: center;
            justify-content: center;
            padding: .6rem;
            color: var(--whiteColor);
            font-weight: 600;
            gap: .5rem;

          }
       &:hover{
        transform: translateY(-10px);
       }
       }

       }

   }

}

@media screen and (min-width:480px){

    .project{
        padding-top: 2rem;
    }
}

@media screen and (min-width:1024px) {

    .project{
        .secContainer{
           
            .secIntro{
                width:100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .secTitle{
                    text-align:center;
                    max-width: 70%;
                   

                    h4{
                      
                        color:var(--blackColor);
                        font-weight: 700;
                     
                        
                    }
                    p{
                        color:var(--blackColor);
                        font-weight: 500;
                        font-size: 2.2rem;
                        text-align:center;
                       
                    }
                }
                .iconsDiv{
                    justify-content: flex-end;
                }
            }

            .mainContent{

                grid-template-columns: repeat(4,1fr);
                gap:1.5rem !important;

             }
        }
        
    }
    
}

@media screen and (min-width:480px) {

    .project{
        .secContainer{
           
            .secIntro{
                .secTitle{
                    text-align: center;
                    p{ line-height: 35px; 
                       margin-left: 50px;
                    }}

            }}}
    
}

@media screen and (min-width:412px) {

    .project{
        .secContainer{
           
            .secIntro{
                .secTitle{
                    text-align: center;
                    p{ line-height: 35px; 
                       margin-left: 50px;
                    }}

            }}}
    
}
@media screen and (min-width:430px) {

    .project{
        .secContainer{
           
            .secIntro{
                .secTitle{
                    text-align: center;
                    p{ line-height: 35px; 
                       margin-left: 50px;
                    }}

            }}}
    
}
